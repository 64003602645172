import React from 'react';

const Home = () => {
  return (
    <div className="container">
      <img 
        src="/images/signature.png" 
        alt="Viktoria Trn" 
        className="signature"
      />
      <p className="intro-text">
        Hi, I'm Viktoria, an aspiring young wanna-be actress. Welcome to my online portfolio!
      </p>
    </div>
  );
};

export default Home;