import React from 'react';

const Contact = () => {
  return (
    <div className="main-content">
      <div className="page-container">
        <h1 className="page-title">Contact</h1>
        {/* Contact form will go here */}
      </div>
    </div>
  );
};

export default Contact;