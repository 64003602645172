// src/pages/Resume.js
import React from 'react';

const Resume = () => {
  return (
    <div className="main-content">
      <div className="page-container">
        <h1 className="page-title">Resume</h1>
        
        {/* Personal Information */}
        <section className="mb-12 bg-gray-50 p-6 rounded-lg">
          <div className="grid md:grid-cols-2 gap-4 font-playfair">
            <div className="space-y-2">
              <p className="font-semibold">Meno: Viktoria Trn</p>
              <p>Vek: 12 rokov</p>
              <p>Adresa: Vlašim, Česká republika</p>
            </div>
            <div>
              <p className="italic text-gray-500 text-sm">
                Kontaktné údaje dostupné na vyžiadanie
              </p>
            </div>
          </div>
        </section>

        {/* Professional Goal */}
        <section className="mb-12">
          <h2 className="text-2xl font-playfair mb-4 border-b border-gray-200 pb-2">
            Cieľ
          </h2>
          <p className="font-playfair leading-relaxed">
            Stať sa herečkou a dabérkou. Som nadšená pre filmy, seriály a anime 
            a chcem prinášať postavám život prostredníctvom herectva a hlasu.
          </p>
        </section>

        {/* Education */}
        <section className="mb-12">
          <h2 className="text-2xl font-playfair mb-4 border-b border-gray-200 pb-2">
            Vzdelanie
          </h2>
          <p className="font-playfair">
            Žiačka 7. ročníka, ZŠ Vorlina, Vlašim, Česká republika
          </p>
        </section>

        {/* Skills */}
        <section className="mb-12">
          <h2 className="text-2xl font-playfair mb-4 border-b border-gray-200 pb-2">
            Zručnosti
          </h2>
          <ul className="grid md:grid-cols-2 gap-3 font-playfair list-disc list-inside">
            <li>Výrazné čítanie a rozprávanie textov</li>
            <li>Schopnosť vyjadriť emócie cez hlas a mimiku</li>
            <li>Spev pred publikom</li>
            <li>Kreativita pri tvorbe vlastných príbehov a postáv</li>
            <li>Pekné maľovanie, spev a umelecká tvorba</li>
          </ul>
        </section>

        {/* Experience */}
        <section className="mb-12">
          <h2 className="text-2xl font-playfair mb-4 border-b border-gray-200 pb-2">
            Skúsenosti
          </h2>
          <ul className="space-y-3 font-playfair list-disc list-inside">
            <li>Účasť na dramatickom krúžku v ZUŠ</li>
            <li>Spev pred publikom na rôznych podujatiach</li>
            <li>Nahrávanie videí a zvukových nahrávok doma</li>
            <li>Absolvovala krúžky: kickbox a tanečný krúžok (v minulosti, ukončené kvôli sťahovaniu do Prahy)</li>
          </ul>
        </section>

        {/* Languages */}
        <section className="mb-12">
          <h2 className="text-2xl font-playfair mb-4 border-b border-gray-200 pb-2">
            Jazyky
          </h2>
          <ul className="space-y-3 font-playfair">
            <li>
              <span className="font-semibold">Bilingválna:</span> slovenský a český jazyk (materinské jazyky)
            </li>
            <li>
              <span className="font-semibold">Anglický jazyk:</span> konverzačne
            </li>
            <li>
              <span className="font-semibold">Ruský a japonský jazyk:</span> začiatočníčka
            </li>
          </ul>
        </section>

        {/* Hobbies */}
        <section className="mb-12">
          <h2 className="text-2xl font-playfair mb-4 border-b border-gray-200 pb-2">
            Záľuby
          </h2>
          <ul className="grid md:grid-cols-2 gap-3 font-playfair list-disc list-inside">
            <li>Pozeranie filmov, seriálov a anime na inšpiráciu</li>
            <li>Tvorba vlastných príbehov a postáv</li>
            <li>Napodobňovanie hlasov a štýlov z obľúbených postáv</li>
            <li>Maľovanie, spev a kreatívna tvorba</li>
          </ul>
        </section>

        {/* Future Goals */}
        <section className="mb-12">
          <h2 className="text-2xl font-playfair mb-4 border-b border-gray-200 pb-2">
            Ciele do budúcnosti
          </h2>
          <ul className="space-y-3 font-playfair list-disc list-inside">
            <li>Chcem pokračovať v dramatickom krúžku a zlepšovať sa v herectve.</li>
            <li>Túžim navštíviť dabingové alebo herecké kurzy.</li>
            <li>Chcem sa sústrediť na individuálne projekty a rozvoj vlastného talentu.</li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default Resume;