import React from 'react';

const Portfolio = () => {
  return (
    <div className="main-content">
      <div className="page-container">
        <h1 className="page-title">Portfólio</h1>
        
        <section className="space-y-8">
          <h2 className="text-2xl font-playfair text-gray-800 mb-6">
            Osobný príbeh
          </h2>
          
          <div className="space-y-6 font-playfair text-lg leading-relaxed text-gray-700">
            <p>
              Volám sa Viktoria a mám 12 rokov. Už od malička som milovala filmy, seriály a rozprávky. 
              Keď som sledovala svoje obľúbené postavy, fascinovalo ma, ako ich hlas a herecký výkon 
              dokáže priniesť emócie a život do každého príbehu.
            </p>
            
            <p>
              Navštevujem dramatický krúžok v ZUŠ, kde spoznávam svet herectva a učím sa pracovať 
              s výrazom a hlasom. Rada spievam pred ľuďmi, čo mi pomáha prekonať trému a cítiť sa 
              sebavedomejšie. Doma ma baví nahrávať videá a skúšať dabovať scény z mojich 
              obľúbených filmov či anime.
            </p>
            
            <p>
              Mojou veľkou inšpiráciou je herečka Eva Burešová, ktorá ma motivuje svojím talentom 
              a vášňou pre umenie. Verím, že raz sa mi podarí priniesť vlastný hlas a emócie 
              do postáv, ktoré si ľudia zamilujú.
            </p>
            
            <p className="italic text-gray-600">
              Toto je moja cesta za splnením sna stať sa herečkou a dabérkou, a hoci som na začiatku, 
              viem, že tento sen má pre mňa veľký význam.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Portfolio;
